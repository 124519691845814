.tag-link {
  text-align: center;
  margin: 0;
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'Source Sans Pro', sans-serif !important;
}

.tag-subtitle {
  text-align: center;
  font-style: italic;
  color: $white-faded;
  margin: 0;
  font-size: 13px;
  text-transform: uppercase;
  font-family: 'Source Sans Pro', sans-serif !important;
}