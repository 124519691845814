.postSubtitle {margin-top: -10px;}

.post-preview {
  > a {
    color: $gray-dark;
    &:hover,
    &:focus {
      text-decoration: none;
      color: $brand-primary;
    }
    > .post-title {
      margin-top: 30px;
      margin-bottom: 10px;
      font-size: 20px;
    }
    > .post-subtitle {
      margin: 0 0 10px 0;
      font-weight: 300;
    }
  }
  > .post-title {
    font-weight: 500;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif !important;
  }
  > .post-meta {
    color: $white-faded;
    font-size: 13px;
    margin-top: -6px;
    text-transform: uppercase;
    font-family: 'Source Sans Pro', sans-serif !important;
    > a {
      text-decoration: none;
      color: $gray-dark;
      text-transform: lowercase;
      &:hover,
      &:focus {
        color: $brand-primary;
        text-decoration: underline;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    > a {
      > .post-title {font-size: 20px;}
    }
  }
}

#head-c {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 40px;

  h1 {
    a {color: $brand-primary !important;}
    font-family: 'Unthrift First Personal' !important;
    font-size: 150px;
    margin-bottom: 0;
  }

  h3 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 3px 0 20px 0;
    color: $brand-primary !important;
  }

  #social {
    color: $gray-muted !important;
    font-size: 16px;
  }
}