.container img {max-width: 100%}

.section-divider {margin-top: 55px !important}

.post-page-title {
  color: $gray;
  font-size: 30px;
  font-weight: 500;
  margin-top: 34px;
  text-rendering: optimizelegibility;
  font-family: 'Source Sans Pro', sans-serif !important;
}

.post-page-meta {
  color: $white-faded;
  font-size: 13px;
  text-transform: uppercase;
  font-family: 'Source Sans Pro', sans-serif !important;
  margin-top: -7px !important;
  a {text-transform: lowercase}
}

#auth-name {margin-bottom: 2px !important}

.author {
  border: 1px solid $white-light;
  border-radius: 3px;
  padding: 23px 15px 15px 15px;
}

.author-bio {
  color: $white-dark !important;
}

.author-social {
  margin-top: 5px;
  color: $white-dark;
  text-decoration: none !important;
  a:hover {color: $white-dark !important}
}

.author-img {
  float: left;
  width: 60px;
}

#author-content {
  overflow: hidden !important;
  margin-top: -10px !important;
  span {margin-top: 0 !important;}
  i.fa {font-size: 14px}
}

.dts {
  text-align: center;
  span {padding: 10px}
}

div.imgGallery {
    display: flex;
    flex-direction: column;
    flex-flow: row wrap;
}

div.imgGallery div.img {
  flex: auto;
  width: 200px;
  margin: 2px;
  text-align: center;
}

div.imgGallery div.img img {
    /*align-self: center;*/
    width: 100%;
    height: auto;
}

div.imgGallery div.img img.singlePic {
    width: 50%;
}

img.post-hero {
    z-index: -1;
}

.post-image-header {
   position: relative;
   width: 100%;
   margin-bottom: 5px;
}

.post-image-header h1 {
   position: absolute;
   bottom: 100px;
   left: 0;
   width: 100%;

   color: white;
   font: 24px/45px Helvetica, Sans-Serif;
   letter-spacing: -1px;
   background: rgb(0, 0, 0); /* fallback color */
   background: rgba(0, 0, 0, 0.7);
   padding: 10px;
}
