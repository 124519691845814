// Brand
$brand-primary: hsl(300, 100, 25);
$red: hsl(359, 97, 70);
$red-light: hsla(359, 97, 70, .5);
$gray: hsl(0, 0, 32);
$grey-light: hsl(150, 3, 88);
$gray-dark: hsl(0, 0, 27);
$gray-muted: hsl(0, 0, 50);
$white-faded: hsl(0, 0, 60);
$pre-background: hsl(0, 0, 96.5);

$white-dark: hsl(0, 0, 77);
$white-light: hsl(0, 0, 93);

// Mixins
@mixin transition-all() {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

@mixin background-cover() {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

@mixin serif() {
  font-family: 'Lora', 'Times New Roman', serif;
}

@mixin sans-serif() {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

// Typography
@font-face {
  font-family: 'Unthrift First Personal';
  src: url('/vendor/canvas/assets/fonts/unthriftfirstpersonal.eot'); /* IE9 Compat Modes */
  src: url('/vendor/canvas/assets/fonts/unthriftfirstpersonal.woff2') format('woff2'), /* Super Modern Browsers */
  url('/vendor/canvas/assets/fonts/unthriftfirstpersonal.woff') format('woff'), /* Pretty Modern Browsers */
  url('/vendor/canvas/assets/fonts/unthriftfirstpersonal.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/vendor/canvas/assets/fonts/unthriftfirstpersonal.svg') format('svg'); /* Legacy iOS */
}
